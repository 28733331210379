import React from 'react'
import SEOTools from '../components/SEOTools'
import { graphql, Link } from 'gatsby'
import { Col, Container, Jumbotron, Row } from 'react-bootstrap'
import { GatsbyImage, getImage, getSrc, StaticImage } from 'gatsby-plugin-image'
import StaticTopNavBarLite from '../components/StaticTopNavBarLite'
import GDPRCookieConsent from '../components/GDPRCookieConsent'

/**
 * Almost a copy of the starteco -page, but with minor differences, targeted for new users arriving from google ads.
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const StartEcoPage = ({ data }) => {
    const heroBg = getImage(data.heroBg)
    const contactBg = getImage(data.contactBg)

    return (
        <>
            <SEOTools
                title='Mobilbetalning, Betala med mobilen | Siru med Ecopayz'
                noTitleTemplate
                description='Enkel mobilbetalning! För att betala dina köp med mobilen i Sverige, börja använda Siru tillsammans med Ecopayz!'
                image={getSrc(data.heroBg.childImageSharp)}>
                <script>
                    {`(function(m, a, i, l, e, r) {
                        m['MailerLiteObject'] = e;
                        function f(){
                            var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);
                        }
                        f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
                        var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
                        _.parentNode.insertBefore(r,_);
                    })(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');

                    var ml_account = ml('accounts', '2156928', 'q7l3b3y5n5', 'load');`}
                </script>
            </SEOTools>

            <GDPRCookieConsent />

            <StaticTopNavBarLite />

            <section className='dark-background-overlay position-relative'>
                <div className='d-flex text-white text-center mb-0 pb-3'>
                    <Container>
                        <Row className='py-5 my-5 d-flex justify-content-center'>
                            <Col md='7'>
                                <h2 className='text-uppercase'>Välkommen!</h2>
                                <p className='spaced-light mb-5'>
                                    För att betala dina köp med mobil i Sverige kan du nu börja använda Siru tillsammans
                                    med ecoPayz!
                                </p>
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={heroBg}
                        role='presentation'
                        placeholder='blurred'
                        loading='eager'
                    />
                </div>
            </section>

            <Jumbotron id='eco-register-section' fluid className='text-center mb-0'>
                <Container>
                    <Row className='py-4 d-flex justify-content-center'>
                        <Col md='10'>
                            <div className='mb-3 mt-4'>
                                <StaticImage
                                    src='../images/logos/ecoPayz-logo.png'
                                    alt='ecopayz-logo'
                                    placeholder='blurred'
                                    width={200}
                                />
                            </div>
                            <p className='spaced-light mb-5 px-5'>
                                ecoPayz är en säker onlinebetalningsplattform som ger dig friheten att göra betalningar
                                över hela världen utan att oroa dig för din säkerhet och integritet. Du kan enkelt göra
                                betalningar med din telefon var som helst där Mastercard* accepteras, med stöd av Siru
                                Mobile - inga fler missade möjligheter!
                            </p>

                            <Link to='/sv/startecoprereg' className='btn btn-success btn-lg px-5 rounded-0'>
                                Öppna ett gratis konto
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>

            <hr />

            <Jumbotron fluid className='text-center mb-0 pb-0 pt-3'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col md='8'>
                            <div className='mb-5'>
                                <StaticImage
                                    src='../images/startecopayz/ecopayz_app.png'
                                    alt='ecopayz-logo'
                                    placeholder='blurred'
                                    width={400}
                                />
                            </div>
                            <h2 className='text-uppercase'>Viktigaste fördelarna</h2>
                            <div className='spaced-light mb-5 text-left'>
                                <ul>
                                    <li>
                                        Med ett ecoPayz konto kan du ta emot, skicka och spendera pengar över hela
                                        världen, allt med hjälp av ett onlinekonto.
                                    </li>
                                    <li>
                                        Siru-betalningar fungerar nu på över 50 000 olika platser via ecoPayz
                                        Mastercard.
                                    </li>
                                    <li>Omedelbara insättningar och uttag - ingen månadsavgift, ingen ränta!</li>
                                    <li>
                                        Det är gratis och enkelt att öppna ett konto på några minuter, utan bankkonto
                                        eller kreditupplysning.
                                    </li>
                                </ul>
                            </div>
                            <div className='mb-5'>
                                <Link to='/sv/startecoprereg' className='btn btn-success btn-lg px-5 rounded-0'>
                                    Öppna ett gratis konto
                                </Link>
                            </div>
                            <p className='text-muted small'>
                                * ecoPayz Mastercard är tillgängligt för innehavare av ecoPayz-konto Silver och högre
                                och omfattas av ecoPayz Mastercard-villkor i Storbritannien och största delen av EU.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>

            <hr />

            <Jumbotron fluid className='text-center mb-0 bg-white py-4'>
                <Container>
                    <Row className='py-5 my-5 d-flex justify-content-center'>
                        <Col lg='2' md='2' xs='4'>
                            <div className='mb-3 pt-3 pt-sm-0'>
                                <StaticImage
                                    src='../images/people/jasper.jpg'
                                    className='rounded-circle'
                                    alt='person'
                                    placeholder='blurred'
                                    width={100}
                                />
                            </div>
                        </Col>
                        <Col md='6' xs='8' className='text-left'>
                            <p className='spaced-light'>
                                "Jag gjorde precis mina första betalningar med Siru Mobile och ecoPayz. Jag kan
                                definitivt rekommendera det"
                            </p>
                            <p className='spaced-light mb-4'>
                                <em> - Jasper från Stockholm</em>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>

            <hr />

            <Jumbotron fluid className='text-center mb-0'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col md='10'>
                            <h2 className='text-uppercase'>Börja nu</h2>
                            <div className='d-flex justify-content-center'>
                                <div className='spaced-light mb-4 text-left'>
                                    <ol>
                                        <li>Öppna ett ecoPayz-konto på några minuter</li>
                                        <li>Insätt pengar med Siru Mobile</li>
                                        <li>Betala på miljontals platser över hela världen</li>
                                    </ol>
                                </div>
                            </div>

                            <Link to='/sv/startecoprereg' className='btn btn-success btn-lg px-5 rounded-0'>
                                Öppna ett gratis konto
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>

            <hr />

            <section>
                <Container>
                    <Row className='d-flex align-items-center justify-content-center text-center py-5 mb-5'>
                        <Col xs='10' className='mt-5'>
                            <Row className='d-flex align-items-center py-4'>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/comeon.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/videoslots.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={70}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/slot.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={90}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/betsson.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/bethard.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={70}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/getlucky.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <hr />

            <section>
                <Container>
                    <Row className='d-flex align-items-center justify-content-center text-center py-5 mb-5'>
                        <Col xs='10' className='mt-5'>
                            <h2 className='text-uppercase mb-5'>Använd på vardagliga och välbekanta platser</h2>
                            <StaticImage
                                src='../images/startecopayz/casinologos/section1/grocery.jpg'
                                alt='grocery'
                                placeholder='blurred'
                                className='border mb-4'
                                height={300}
                            />
                            <Row className='d-flex align-items-center justify-content-center py-4'>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section1/willys.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={80}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section1/coop.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={40}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section1/pressbyran.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={70}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section1/hemkop.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section1/ica.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={40}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <hr />

            <section>
                <Container>
                    <Row className='d-flex align-items-center justify-content-center text-center py-5 mb-5'>
                        <Col xs='10' className='mt-5'>
                            <StaticImage
                                src='../images/startecopayz/casinologos/section2/fillerup.jpg'
                                alt='grocery'
                                placeholder='blurred'
                                className='border mb-4'
                                height={300}
                            />
                            <Row className='d-flex align-items-center justify-content-center py-4'>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section2/st1.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section2/shell.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section2/preem.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section2/circle.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section2/ok.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <hr />

            <section>
                <Container>
                    <Row className='d-flex align-items-center justify-content-center text-center py-5 mb-5'>
                        <Col xs='10' className='mt-5'>
                            <StaticImage
                                src='../images/startecopayz/casinologos/section3/coffee.jpg'
                                alt='grocery'
                                placeholder='blurred'
                                className='border mb-4'
                                height={300}
                            />
                            <Row className='d-flex align-items-center justify-content-center py-4'>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section3/foodora.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section3/m.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section3/wolt.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section3/subway.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section3/7eleven.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section3/eats.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='darker-background-overlay position-relative'>
                <div className='d-flex text-white text-center mb-0'>
                    <Container>
                        <Row className='py-5 my-5 justify-content-center'>
                            <Col md='10'>
                                <div
                                    className='ml-form-embed'
                                    data-account='2156928:q7l3b3y5n5'
                                    data-form='5678369:c0e3z4'
                                />
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={contactBg}
                        role='presentation'
                        placeholder='blurred'
                        loading='lazy'
                    />
                </div>
            </section>
        </>
    )
}

export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "products/sirupay/sirupay_hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
        contactBg: file(relativePath: { eq: "sirumobile_contact_us.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`

export default StartEcoPage
